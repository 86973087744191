import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { TailSpin } from  'react-loader-spinner';
import Colors from '../library/Colors';
import CardWithImageSmall from '../components/CardWithImageSmall';
import './logging/logging.css';
import SftGlobalData from '../library/SftGlobalData';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MySoftTouch from '../assets/application-icons/ic_mysft.png';
import MySales from '../assets/application-icons/ic_mysales.png';
import MyFashionManager from '../assets/licence-icons/office.png';
import Consultation from '../assets/mobile-icons/ic_consultation.png';
import Collection from '../assets/licence-icons/collection.png';
import Cloud from '../assets/licence-icons/cloud.png';
import MyFasmanMobile from '../assets/module-icons/ic_mobile.png';
import MyFasmanRevenue from '../assets/application-icons/ic_revenue.png';
import Webshop from '../assets/mobile-icons/ic_webshop.png';
import Integrations from '../assets/licence-icons/integrations.png';
import Hardware from '../assets/licence-icons/hardware.png';
import Others from '../assets/licence-icons/other.png';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PersonIcon from '@mui/icons-material/Person';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import UpdateIcon from '@mui/icons-material/Update';
import crypto from 'crypto-js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash, faCheckCircle, faCopy, faLink } from '@fortawesome/free-solid-svg-icons';
import { InputAdornment, TextField } from '@mui/material';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  

function LinkCell (props) {
    return (<Link to={"/"+props.lang+"/manuals/view/"+props.id} style={{width: '100%', height: '100%', alignContent: 'center', alignItems: 'center', display: 'flex'}}>{props.children}</Link>)
}

const ManualScreen = (props) =>  {  
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [applications, setApplications] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [applicationFilter, setApplicationFilter] = useState("");
    const [includeApproved, setIncludeApproved] = useState(true);
    const [includeUpdates, setIncludeUpdates] = useState(true);
    const [onlyApproved, setOnlyApproved] = useState(false);
    const [onlyUpdates, setOnlyUpdates] = useState(false);
    const initialGrid = getCookie("initialStateDocs") === "{}" ? {columns: {columnVisibilityModel: {},}} : JSON.parse(getCookie("initialStateDocs"));
    const gridColumns = [
        {
            field: 'icon',
            headerName: "",
            minWidth: 38,
            maxWidth: 50,
            headerAlign: 'left',
            align: 'left',        
            renderCell: (params) => {
                return <LinkCell id={params.row.id} lang={params.row.lang} theme={params.row.theme} ><img alt={params.row.title + " icon"} src={params.row.icon}  style={{objectFit: 'contain', height: '32px', width: '32px'}} loading={"lazy"} /></LinkCell>}
        },
        {
            field: 'title',
            headerName: props.t('manual'),
            minWidth: 300,
            headerAlign: 'left',
            align: 'left',        
            renderCell: (params) => {
                return <LinkCell id={params.row.id} lang={params.row.lang} theme={params.row.theme} ><span>{params.row.title}</span></LinkCell>}
        },
        {
            field: 'type',
            headerName: props.t('type'),
            minWidth: 50,
            maxWidth: 75,  
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return params.row.type === "VERSION" ? <UpdateIcon style={{color: Colors.sft_blue}}/> : <LibraryBooksIcon style={{color: Colors.sft_blue}}/>
                }
        },
        {
            field: 'visibility',
            headerName: props.t('visibility'),
            minWidth: 50,    
            maxWidth: 75,  
            align: 'center',      
            headerAlign: 'center', 
            renderCell: (params) => {
              return params.row.visibility === "INTERNAL" ? <SupportAgentIcon style={{color: Colors.sft_blue}}/> : (params.row.visibility === "DEV" ? <IntegrationInstructionsIcon style={{color: Colors.sft_blue}}/> : <PersonIcon style={{color: Colors.sft_blue}}/>)
              }
        },
        {
            field: 'sort',
            headerName: props.t('sort'),
            minWidth: 100,    
            maxWidth: 120,  
            numeric: true,
            type: 'number',
            align: 'center',      
            headerAlign: 'center',
        },
        {
            field: 'tags',
            headerName: props.t('tags'),
            minWidth: 200,
            headerAlign: 'left',
            align: 'left'
        },
        {
            field: 'edit',
            headerName: props.t('edit'),
            minWidth: 60,    
            maxWidth: 60,  
            align: 'center',      
            headerAlign: 'center', 
            renderCell: (params) => {
            return  ((props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") || (!params.row.confirmed /*&& props.login.id === params.row.created_by*/)) ? <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%" }} onClick={()=>{navigate("/"+props.lang+"/manuals/edit/"+params.row.id)}} >
                        <FontAwesomeIcon icon={faPencilAlt} color={Colors.def_orange} size="2x" />
                    </Button> : <div></div>;        
            }
        },
        {
            field: 'copy',
            headerName: props.t('copy'),
            minWidth: 60,    
            maxWidth: 60,  
            align: 'center',      
            headerAlign: 'center', 
            renderCell: (params) => {
              return  <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%" }} onClick={()=>{onDuplicateManual(params.row.id)}} ><FontAwesomeIcon icon={faCopy} color={Colors.def_grey} size="2x" /></Button>
              }
        },
        {
            field: 'delete',
            headerName: "",
            minWidth: 60,    
            maxWidth: 60,  
            align: 'center',      
            headerAlign: 'center', 
            renderCell: (params) => {
              return  (props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") ? <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%" }} onClick={()=>{onDeleteManual(params.row.id)}} >
                          <FontAwesomeIcon icon={faTrash} color={Colors.def_red} size="2x" />
                      </Button> : <div></div>;        
              }
        },
        {
              field: 'confirm',
              headerName: "",
              minWidth: 60,    
              maxWidth: 60,  
              align: 'center',      
              headerAlign: 'center', 
              renderCell: (params) => {
                return  (params.row.confirmed === 0 && (props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER")) ? <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%" }} onClick={()=>{onActivateManual(params.row.id)}} ><FontAwesomeIcon icon={faCheckCircle} color={Colors.def_green} size="2x" /></Button>
                                                   : <div></div>
                }
        },
        {
            field: 'link',
            headerName: "",
            minWidth: 60,    
            maxWidth: 60,  
            align: 'center',      
            headerAlign: 'center', 
            renderCell: (params) => {
              return  (params.row.type === "VERSION" && (props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER")) ? <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%" }} onClick={()=>{documentLink(params.row)}} ><FontAwesomeIcon icon={faLink} color={Colors.def_grey} size="2x" /></Button>
                                                 : (params.row.type !== "VERSION" ? <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%" }} onClick={()=>{manualLink(params.row)}} ><FontAwesomeIcon icon={faLink} color={Colors.def_grey} size="2x" /></Button> : <div></div>)
              }
      },
      ];
    let navigate = useNavigate();

    const documentLink = (doc) => {
        let hash = crypto.MD5(parseInt((new Date(doc.created_at).getTime() / 1000).toFixed(0))+"-"+doc.id).toString();
        navigator.clipboard.writeText("https://changelog.softtouch.eu/"+props.lang+"/red/"+hash);
        props.notify(props.t('copied') + " changelog URL", 2000, "top-right", props.theme, 1); 
        
    }

    const manualLink = (doc) => {
        navigator.clipboard.writeText("https://my.softtouch.eu/"+props.lang+"/authentication/"+SftGlobalData.defaultKey+"/kb/"+doc.id);
        props.notify(props.t('copied') + " manual URL", 2000, "top-right", props.theme, 1); 
        
    }

    useEffect(() => {
        props.changeScreen('logging');
        try{
            let sessionUpdates = sessionStorage.getItem('updates');
            let sessionApproved = sessionStorage.getItem('approved');
            let sessionSearch = sessionStorage.getItem('docSearch');
            let sessionFilter = sessionStorage.getItem('docApp');
            
            if(sessionUpdates !== null){setIncludeUpdates(sessionUpdates === 'true');}
            if(sessionApproved !== null){setIncludeApproved(sessionApproved === 'true');}
            if(sessionSearch !== null){setSearchValue(sessionSearch);}
            if(sessionFilter !== null){setApplicationFilter(sessionFilter);}
        }catch(ex){}

        let url = SftGlobalData.baseURL_API + 'docs&action=fetchApplications';
        axios.post(url, 
            {
                sft_id: props.login.sft_id,
                sft_group: props.login.sft_group,
                login: props.login.login            
            }, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            }).then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('response', response);
                }            
                setApplications(response.data);
        });

        fetchHeaders();
      }, [props.api, props.login]);

      const fetchHeaders = () => {
        let url2 = SftGlobalData.baseURL_API + 'docs&action=fetchHeaders';

        axios.post(url2, 
            {
                sft_id: props.login.sft_id,
                sft_group: props.login.sft_group,
                login: props.login.login            
            }, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            }).then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url2);
                    console.log('response', response);
                }            
                setHeaders(response.data);
                setLoading(false);
        });
      }

      const showApplications = applications.map(element => {         
            return <CardWithImageSmall key={element.id} name={element.name} src={getApplicationIcon(element.name)} theme={props.theme} func={()=>{if(element.id === applicationFilter) {setApplicationFilter("");sessionStorage.setItem('docApp', "");} else {setApplicationFilter(element.id);sessionStorage.setItem('docApp', element.id);}}} style={applicationFilter === element.id ? {backgroundColor: (props.theme === "dark" ? Colors.dark_accent_darker : Colors.light_accent_darkest)} : {}} />
        });

      function getApplicationIcon(names){
        switch (names){
            case "1":
            case "MySales": 
                return MySales;
            case "2":
            case "MyFashionManager": 
                return MyFashionManager;
            case "3":
            case "Consultation": 
                return Consultation;
            case "4":
            case "Collection": 
                return Collection;
            case "5":
            case "Cloud": 
                return Cloud;
            case "6":
            case "MyFasman Mobile": 
                return MyFasmanMobile;
            case "7":
            case "MyFasman Revenue": 
                return MyFasmanRevenue;
            case "8":
            case "Webshop": 
                return Webshop;
            case "9":
            case "Integrations": 
                return Integrations;
            case "10":
            case "Hardware": 
                return Hardware;
            case "11":
            case "MySoftTouch":
                return MySoftTouch;
            default:
            case "12":
            case "Others": 
                return Others;       
        }
      }



const onDuplicateManual = (id) => {
    if (window.confirm(props.t('confirm_duplicate_document')) === true) {
        let url = SftGlobalData.baseURL_API + 'docs&action=duplicate';
        let postData = {
            sft_id: props.login.sft_id,
            sft_group: props.login.sft_group,
            login: props.login.login,
            id: id
        }

        axios.post(url, postData, 
            {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            }).then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('response', response);
                }            
                fetchHeaders();
        });
    }
}

const onActivateManual = (id) => {
    if (window.confirm(props.t('confirm_activate_document')) === true) {
        let url = SftGlobalData.baseURL_API + 'docs&action=activate';
        let postData = {
            sft_id: props.login.sft_id,
            sft_group: props.login.sft_group,
            login: props.login.login,
            id: id,
            active: true
        }

        axios.post(url, postData, 
            {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            }).then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('response', response);
                }            
                fetchHeaders();
        });
    }
}

const onDeleteManual = (id) => {
    if (window.confirm(props.t('confirm_delete_document')) === true) {
        let url = SftGlobalData.baseURL_API + 'docs&action=delete';
        let postData = {
            sft_id: props.login.sft_id,
            sft_group: props.login.sft_group,
            login: props.login.login,
            id: id
        }

        axios.post(url, postData, 
            {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            }).then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('response', response);
                }            
                fetchHeaders();
        });
    }
}


    const apiRef = useGridApiRef();
    
    
    useEffect(()=>{
        //console.log("adding subscriptions");
        try{
            apiRef.current.subscribeEvent(
                'columnOrderChange',
                ()=>{visibilityChangedHandler();},
            );
        }catch(ex){
    
        }
    }, [apiRef.current]);

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        //console.log("cookie", cname + "=" + cvalue + ";" + expires + ";path=/");
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "{}";
    }

    const visibilityChangedHandler = () => {
        let temp = apiRef.current.exportState();
        if(typeof temp.columns.columnVisibilityModel === "undefined"){
            temp.columns.columnVisibilityModel = {};
        }
        try{
            temp.preferencePanel.open = false;
        }catch(e){
    
        }
        try{
            delete temp.pagination;
        }catch(e){
            console.error(e);
        }
        setCookie("initialStateDocs", JSON.stringify(temp), 100000);
    }
    

  return (
    <div style={props.screen === "logging" ? {maxHeight: 750} : {}} className={(props.screen === "logging" ? ('log__margin_sidebar log__container_content _80perc ' + props.theme + ' container_content_' + props.theme + '  box_fade-in') : (props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme)} >
        <div className="container_list__title padding_top__10px">
            <h2 className={(props.theme==='dark') ? "dark container_list__title" : "light container_list__title"} type="text">Manuals</h2>
        </div>
        {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
            <TailSpin 
                height="40"
                width="40"
                color={Colors.sft_blue}
                ariaLabel='loading'
            />
        </div>}
        {!loading && <div style={{display: 'flex', flexDirection: 'column', height: '90%', padding: 1}}>
            <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                <TextField
                    id="input-with-icon-textfield"
                    autoComplete="off" 
                    className={props.theme+ '_accent margin_right__10px'}
                    sx={{ borderRadius: 3, marginTop: 0 }}
                    value={searchValue}
                    size={'small'}
                    fullWidth
                    placeholder={props.t('search')}
                    type="search"
                    onChange={(e) => {setSearchValue(e.target.value); sessionStorage.setItem('docSearch', e.target.value);}}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{color: Colors.sft_blue}} />
                        </InputAdornment>
                      ),
                    }}
                    onFocus={(event) => event.target.select()}   
               />
                {(props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") && <Button sx={{ borderRadius: 3, paddingLeft: 3, paddingRight: 3}} className={"log__small_button_width margin_right__5px " + (onlyApproved ? "yellow" : includeApproved ? "green" : "red")} onDoubleClick={()=>{setOnlyApproved(true);}} onClick={()=>{setOnlyApproved(false); setIncludeApproved(!includeApproved); sessionStorage.removeItem('approved'); sessionStorage.setItem('approved', !includeApproved);}}><FontAwesomeIcon icon={faCheckCircle} color={Colors.dark_text} size="1x" /></Button>}
                {(props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") && <Button sx={{ borderRadius: 3, paddingLeft: 3, paddingRight: 3}}className={"log__small_button_width margin_right__5px " + (onlyUpdates ? "yellow" : includeUpdates ? "green" : "red")} onDoubleClick={()=>{setOnlyUpdates(true);}} onClick={()=>{setOnlyUpdates(false); setIncludeUpdates(!includeUpdates); sessionStorage.removeItem('updates'); sessionStorage.setItem('updates', !includeUpdates);}}><UpdateIcon style={{color: 'white'}}/></Button>}
                
                <Button 
                    variant="outlined" 
                    sx={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, color: Colors.sft_blue, paddingLeft: 3, paddingRight: 3}}
                    className="log__small_button_width" 
                    onClick={()=>{navigate("/"+props.lang+"/manuals/edit/+")}}>
                    <AddIcon style={{Color: Colors.sft_blue}}/>
                </Button>
               
           </div>

            <div className='log__container_options'>
                {showApplications}
            </div>
            <DataGridPro
                  apiRef={apiRef}
                  initialState={initialGrid}
                  className={'manuals_application_headers ' + props.theme}
                  columns={gridColumns}
                  rows={headers.filter((row)=>( (onlyApproved ? row.active === "0" : (includeApproved ? true : row.active !== "0")) && (onlyUpdates ? row.type === "VERSION" : (includeUpdates ? true : row.type !== "VERSION")) && ((row.type?.match(new RegExp(searchValue, "i")) || row.visibility?.match(new RegExp(searchValue, "i")) || row.tag?.match(new RegExp(searchValue, "i")) || row.title_nl?.match(new RegExp(searchValue, "i")) || row.title_en?.match(new RegExp(searchValue, "i")) || row.title_fr?.match(new RegExp(searchValue, "i"))) && ((row.application_id === applicationFilter) || applicationFilter === ""   )))).map((row, index) => {return {
                          id: row.id,
                          lang: props.lang,
                          title: (props.lang === "nl" ? row.title_nl : (props.lang === "fr" ? row.title_fr : row.title_en)),
                          visibility: row.visibility,
                          type: row.type,
                          created_at: row.created_at,
                          updated_at: row.updated_at,
                          created_by: row.created_by,
                          updated_by: row.updated_by,
                          sort: row.sort,
                          tags: row.tag,
                          edit: "Edit",
                          delete: "Delete",
                          copy: "Copy",
                          confirmed: row.active !== "0" ? 1 : 0,
                          icon: getApplicationIcon(row.application_id)
                      }                          
                  })} 
                  onColumnWidthChange={()=>{visibilityChangedHandler();}}
                  onColumnVisibilityModelChange={()=>{visibilityChangedHandler();}}
                  onPreferencePanelClose={()=>{visibilityChangedHandler();}}
                  rowThreshold={0}
                  rowHeight={38}
                  height={300}
                  loading={loading}
                  pagination={false}

                  /> 
                
        </div>}
    </div>
  );
}

export default ManualScreen;
