import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Button from '@mui/material/Button';
import logo_light from '../../assets/header/logo_light.png';
import logo_dark from '../../assets/header/logo_dark.png';
import '../../styles/main.css';
import './quiz.css';
import Colors from '../../library/Colors';
import { TextField } from '@mui/material';
import QuizMusic from '../../assets/quiz.mp3';


const QuizScreen = (props) =>  {
    const [audio] = useState(new Audio(QuizMusic));
    const [loading, setLoading] = useState(false);
    const [disable, setDisable] = useState(false);
    const [points, setPoints] = useState(0);
    const [question, setQuestion] = useState(-1);
    const [answer, setAnswer] = useState("");
    const [reveal, setReveal] = useState(false);
    const [results, setResults] = useState(false);
    const [timer, setTimer] = useState(0); // Timer value in seconds
    const [isRunning, setIsRunning] = useState(false); // To control the timer
    const [currentQuestion, setCurrentQuestion] = useState({
        question_nl: "",
        question_fr: "",
        question_en: "",
        question_type: "choice",
        points: 0,
        answer: "",
        option_a: "A",
        option_b: "B",
        option_c: "C",
        option_d: "D",
    });
    const [questions, setQuestions] = useState([
        // Real questions
        {   // General
            question_nl: "Wat is het telefoonnummer van de SoftTouch Vlaanderen helpdesk? (Geen spaties, geen speciale karakters, start met 0, zonder 32)",
            question_fr: "Quel est le numéro de téléphone du service d'assistance de SoftTouch Flandres (sans espaces, sans caractères spéciaux, commençant par 0, sans 32)?",
            question_en: "What is the phone number of the SoftTouch Vlaanderen helpdesk? (No spaces, no special characters, starts with 0 and no 32.)",
            question_type: "input",
            points: 10,
            answer: "092190083",
            option_a: "",
            option_b: "",
            option_c: "",
            option_d: "",
        },
        {   // Support
            question_nl: "Wie was de top caller of the year van SoftTouch Vlaanderen in 2023?",
            question_fr: "Qui a été le meilleur interlocuteur de SoftTouch Flandres en 2023?",
            question_en: "Who was the top caller of the year of SoftTouch Vlaanderen 2023?",
            question_type: "choice",
            points: 3,
            answer: "The Fashion Store",
            option_a: "Beau 4 / C Beau / Voile Du Nord",
            option_b: "Lingerie An Sofie",
            option_c: "The Fashion Store",
            option_d: "Mode Meert",
        },
        {   // Office / Marketing / Management
            question_nl: "Wanneer je in SFT vlaanderen zoekt op \"Lingerie\", hoeveel resultaten krijg je terug? (Enkel klantcode \"Klant\")",
            question_fr: "Lorsque vous recherchez « Lingerie » dans SFT vlaanderen, combien de résultats obtenez-vous? (Code \"client\" uniquement).",
            question_en: "When you search for \"Lingerie\" in the SFT vlaanderen environment, how many results would you get? (Only actual customers, no prospects / stopped / ...)",
            question_type: "choice",
            points: 5,
            answer: "49",
            option_a: "42",
            option_b: "49",
            option_c: "53",
            option_d: "69",
        },
        {   // Management
            question_nl: "In welk jaar werd Kristof Van Steenkiste geboren?",
            question_fr: "En quelle année est né Kristof Van Steenkiste?",
            question_en: "In which year was Kristof Van Steenkiste born?",
            question_type: "choice",
            points: 3,
            answer: "1985",
            option_a: "1982",
            option_b: "1985",
            option_c: "1987",
            option_d: "1995",
        },
        {   // Support
            question_nl: "Hoeveel huurscanners heeft SoftTouch Vlaanderen? (enkel smartscanners / geen cipherlabs / geen cradles / geen dev toestellen)",
            question_fr: "Combien de scanners de location SoftTouch Flandres possède-t-il? (seulement smartscanners / pas de cipherlabs / pas de cradles / pas de dev devices)",
            question_en: "How many smartscanners are up for renting in SoftTouch Vlaanderen? (Only smartscanners so no cipherlabs, no cradles and no dev devices.)",
            question_type: "input",
            points: 5,
            answer: "20",
            option_a: "",
            option_b: "",
            option_c: "",
            option_d: "",
        },
        {   // Marketing
            question_nl: "Hoeveel keer heeft Google contact gehad met onze dienst marketing (Maud)?",
            question_fr: "Combien de fois notre service marketing a-t-il été en contact direct avec Google? (Maud étant notre service marketing, bien entendu)",
            question_en: "How many times has our marketing had direct contact with Google? (Maud being our marketing, of course)",
            question_type: "choice",
            points: 5,
            answer: "6",
            option_a: "3",
            option_b: "5",
            option_c: "6",
            option_d: "9",
        },
        {   // Management
            question_nl: "Tijdens welke periode is het Jeroen zijn \"Momentje van de dag\"?",
            question_fr: "A quelle période se situe le « Moment de la journée » de Jeroen?",
            question_en: "Which period of the day is considered Jeroen's \"Favorite moment of the day\"?",
            question_type: "choice",
            points: 5,
            answer: "14u30 - 15u30",
            option_a: "13u36 - 14u30",
            option_b: "14u30 - 15u30",
            option_c: "15u30 - 16u30",
            option_d: "16u30 - 17u30",
        },
        {   // EDI
            question_nl: "Welke van de onderstaande EDI types bestaat niet / ondersteunen we niet?",
            question_fr: "Lequel des types EDI ci-dessous n'existe pas / n'est pas pris en charge?",
            question_en: "Which of the following EDI types does not exist / do we not support?",
            question_type: "choice",
            points: 5,
            answer: "INVOIC",
            option_a: "SLSRPT",
            option_b: "DESADV",
            option_c: "ORDRSP",
            option_d: "INVOIC",
        },
        {   // Office
            question_nl: "Wanneer kan de boekhouding de openstaande debiteuren het beste controleren?",
            question_fr: "Quel est le meilleur moment pour le comptable pour contrôler les débiteurs en souffrance?",
            question_en: "When is the best time for accounting (Els) to check the outstanding debtors?",
            question_type: "choice",
            points: 5,
            answer: "Iedere dinsdag",
            option_a: "Aan het begin van de maand",
            option_b: "Aan het einde van de maand",
            option_c: "Iedere dinsdag",
            option_d: "Iedere donderdag",
        },
        {   // Support
            question_nl: "Wat was het hoogste aantal oproepen in 1 maand op de support van SoftTouch Vlaanderen in 2023?",
            question_fr: "Quel a été le nombre le plus élevé d'appels en 1 mois sur le support de SoftTouch Flandres en 2023?",
            question_en: "What was the highest amount of calls in 1 month on the support of SoftTouch Vlaanderen in 2023?",
            question_type: "choice",
            points: 5,
            answer: "756",
            option_a: "756",
            option_b: "736",
            option_c: "636",
            option_d: "569",
        },
        {   // Website
            question_nl: "Wanneer werd de nieuwe website van SoftTouch live gezet?",
            question_fr: "Quand le nouveau site web de SoftTouch a-t-il été mis en ligne?",
            question_en: "When was the new website of SoftTouch released?",
            question_type: "choice",
            points: 5,
            answer: "2 Dec 2024",
            option_a: "1 Dec 2024",
            option_b: "2 Dec 2024",
            option_c: "3 Dec 2024",
            option_d: "30 Nov 2024",
        },
        {   // MyFasMan Revenue
            question_nl: "Wat is het hoogst aantal gemeten gebruikers op 1 dag van de omzet app in 2023?",
            question_fr: "Quel est le nombre le plus élevé d'utilisateurs mesurés sur une journée de l'application de revenus en 2023?",
            question_en: "What is the highest amount of daily users seen in the revenue app in 2023?",
            question_type: "choice",
            points: 3,
            answer: "347",
            option_a: "347",
            option_b: "334",
            option_c: "317",
            option_d: "297",
        },
        {   // MyFasMan Mobile
            question_nl: "Hoeveel modules heeft de MyFasMan Mobile app? (Tip: vergeete de label module voor Rackstore niet!)",
            question_fr: "Combien de modules l'application MyFasMan Mobile comporte-t-elle? (Conseil : n'oubliez pas le module d'étiquetage pour le Rackstore !)",
            question_en: "How many modules does the MyFasMan Mobile app have? (Hint: don't forget the label module for Rackstore!)",
            question_type: "input",
            points: 10,
            answer: "19",
            option_a: "",
            option_b: "",
            option_c: "",
            option_d: "",
        },
        {   // General
            question_nl: "Wie werkt het langste bij SoftTouch?",
            question_fr: "Qui travaille chez SoftTouch depuis le plus longtemps?",
            question_en: "Who has been working in SoftTouch the longest?",
            question_type: "choice",
            points: 3,
            answer: "Tom",
            option_a: "Jeroen",
            option_b: "Kristof",
            option_c: "Tom",
            option_d: "Louis",
        },
        {   // Webshop
            question_nl: "Hoeveel mensen hebben aan de code gewerkt van de SoftTouch Webshop (niet API)?",
            question_fr: "Combien de personnes ont travaillé sur le code du SoftTouch Webshop (pas l'API)?",
            question_en: "How many people have worked on the code of the SoftTouch Webshop (not the API code)?",
            question_type: "input",
            points: 5,
            answer: "5", // Bert M., Stefan DB, Kristof VS, Bert VdV, Mathias
            option_a: "",
            option_b: "",
            option_c: "",
            option_d: "",
        },
        {   // MyFashionManager
            question_nl: "Welke van de volgende opties zitten NIET in het configuratie menu van de MyFashionManager ((back)office)?",
            question_fr: "Parmi les options suivantes, lesquelles NE figurent PAS dans le menu de configuration de MyFashionManager ((back)office)?",
            question_en: "Which of the following options are NOT in the configuration menu of the MyFashionManager ((back)office)?",
            question_type: "choice",
            points: 5,
            answer: "Vennootschappen",
            option_a: "Configuratie",
            option_b: "Targets",
            option_c: "Vennootschappen",
            option_d: "Korting types",
        },
        {   // MySales
            question_nl: "In de MySales (kassa) in het overzicht van de transacties... Welke knop doet meestal niks?",
            question_fr: "Dans MySales (checkout), dans l'aperçu des transactions... Quel bouton ne fait généralement rien?",
            question_en: "In the MySales (cash register), in the overview of transactions... Which button usually doesn't do anything?",
            question_type: "choice",
            points: 5,
            answer: "Klant",
            option_a: "Labels",
            option_b: "Datum",
            option_c: "Klant",
            option_d: "Terug",
        },
        {   // MyFasMan Mobile
            question_nl: "Hoeveel types smartscanners ondersteunt de MyFasMan Mobile? (Tip: Met type bedoelen we in dit geval de verschillende merken.)",
            question_fr: "Combien de types de smartscanners le MyFasMan Mobile supporte-t-il? (Conseil : par type, nous entendons les différentes marques dans ce cas.)",
            question_en: "How many types of smartscanners does the MyFasMan Mobile app support? (Hint: With type we mean how many different brands in this case.)",
            question_type: "choice",
            points: 5,
            answer: "7",
            option_a: "4",
            option_b: "5",
            option_c: "6",
            option_d: "7",
        },
        {   // Website
            question_nl: "Hoeveel oplossingen zijn er volgens de softtouch.eu website?",
            question_fr: "Combien de solutions y a-t-il selon le site web softtouch.eu?",
            question_en: "How many solutions are there according to the softtouch.eu website",
            question_type: "input",
            points: 5,
            answer: "5",
            option_a: "",
            option_b: "",
            option_c: "",
            option_d: "",
        },
        {   // General
            question_nl: "Hoeveel parkeer vakken zijn er op de parking van SoftTouch Vlaanderen?",
            question_fr: "Combien de places de stationnement y a-t-il dans le parking de SoftTouch Flandres?",
            question_en: "How many parking tiles are there on the parking of SoftTouch Flanders?",
            question_type: "input",
            points: 3,
            answer: "7",
            option_a: "",
            option_b: "",
            option_c: "",
            option_d: "",
        },
    ]);

    //---------------------------------------------------------------------------------------------
    //    USE EFFECTS
    //---------------------------------------------------------------------------------------------

    useEffect(() => {
        props.setHideSidebar(true);
        props.changeScreen('quiz');
        //shuffleArray();
        setCurrentQuestion(questions[0])

    },[props.api]);

    useEffect(() => {
        let interval;
        if (isRunning) {
          interval = setInterval(() => {
            setTimer((prev) => prev + 1);
          }, 1000); // Update timer every second
        }
    
        // Cleanup the interval when component unmounts or isRunning changes
        return () => clearInterval(interval);
    }, [isRunning]);

    useEffect(() => {
        // Set up event listener to restart the audio when it ends
        const handleEnd = () => {
          audio.currentTime = 0;
          audio.play();
        };
    
        audio.addEventListener('ended', handleEnd);
    
        // Clean up the event listener on component unmount
        return () => {
          audio.removeEventListener('ended', handleEnd);
        };
    }, [audio]);

    useEffect(() => {
    
        // Clean up the audio on component unmount
        return () => {
          audio.pause();
          audio.currentTime = 0;
        };
    }, [audio]);

    //---------------------------------------------------------------------------------------------
    //    FUNCTIONS
    //---------------------------------------------------------------------------------------------

    const handleStop = () => {
        setIsRunning(false); // Stop the timer
        console.log("Final Timer Value:", timer); // Log or use the timer value
    };

    const shuffleArray = () => {
        // Fisher-Yates shuffle algorithm
        for (let i = questions.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [questions[i], questions[j]] = [questions[j], questions[i]];
        }
    
        setQuestions([...questions]); // Update state to trigger re-render
      };

    const getQuestion = () => {
        switch(props.lang){
            case "nl":
                return <p>{currentQuestion.question_nl} <br/>( <i>{currentQuestion.points}</i> )</p>;
            case "fr":
                return <p>{currentQuestion.question_fr} <br/>( <i>{currentQuestion.points}</i> )</p>;
            default: 
                return <p>{currentQuestion.question_en} <br/>( <i>{currentQuestion.points}</i> )</p>;
        }
    }

    const next = () => {
        if(answer !== ""){
            if(reveal){
                if(SftGlobalData.debug){
                    console.log("POINTS", points);
                }
                setReveal(false);
                setAnswer("");
                if(question+1 < questions.length){
                    setQuestion(question+1);
                    setCurrentQuestion(questions[question+1]);
                    setDisable(false);
                } else {
                    setResults(true);
                    handleStop();
                    let url = SftGlobalData.baseURL_API + 'quiz&action=score';
                    let postData = {key: props.api, lic_account_id: props?.login?.login, score: points, time: timer};
                    axios.post(url, postData, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }, timeout: 600000}) 
                        .then(response => {
                            if(SftGlobalData.debug){
                                console.log('url', url);
                                console.log("postData", postData);
                                console.log('response', response);
                            }
                    });
                }
            } else {
                setDisable(true);
                setReveal(true);
                if(answer === currentQuestion.answer){
                    setPoints(points + currentQuestion.points);
                }
            }
            
            
        } else {
            props.notify("Please give an answer.", 2000, "top-center", props.theme, 777); 
        }
        
    }

    //---------------------------------------------------------------------------------------------
    //    OUTPUT
    //---------------------------------------------------------------------------------------------

    return (
        <div className='columnContainerStart _100perc' style={{alignItems: "center", padding: 5}} >
            <img alt='SoftTouch Logo' src={(props.theme==='dark') ? logo_dark : logo_light} className='login__logo' style={{marginBottom: 10}}/>

            {(typeof props.login?.first_name !== "undefined" && question === -1) && <div className={"general_card_container border_radius mobile_card text_center columnContainerCenter " + props.theme}>
                <Button 
                    disabled={loading} 
                    variant="outlined" 
                    sx={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, color: Colors.sft_blue}}
                    onClick={()=>{setQuestion(0); audio.play(); setIsRunning(true);}}>
                    START
                </Button>
            </div>}

            {(typeof props.login?.first_name !== "undefined" && question !== -1) && <div className={"general_card_container border_radius mobile_card text_center columnContainerBetween " + props.theme}>
                
                {results && <div className='columnContainerCenter' style={{alignItems: "center", width: "100%", height: "100%"}}>
                    Thanks for playing, {props.login.first_name},<br/><br/>
                    You have a score of...<br/>
                    <h2>{points} points</h2>
                    <Button 
                        disabled={loading} 
                        variant="outlined" 
                        sx={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, color: Colors.sft_blue}}
                        onClick={()=>{setQuestion(0);setTimer(0);setReveal(false);setAnswer("");shuffleArray();setCurrentQuestion(questions[0]);setPoints(0);setDisable(false);setResults(false);}}>
                        RESTART
                    </Button>
                </div>}

                {!results && <div style={{width: "100%", height: "40%", borderBottom: 1, borderBottomColor: Colors.sft_blue, borderBottomStyle: "solid"}}>
                    {!results && <h2>{props.t("question")} {question+1}</h2>}
                    {getQuestion()}
                </div>}
                {!results && <div className={" columnContainerBetween "} style={{width: "100%", height: "49%"}}>
                    
                    {currentQuestion.question_type === "input" && <div className={" columnContainerStart "}>
                        
                        <div style={{justifyContent: 'flex-start', textAlign: "left", alignItems: "flex-start"}}>
                            <TextField
                                minRows={1}
                                disabled={disable}
                                autoComplete="off" 
                                className={props.theme+ '_accent _100perc'}
                                id="answer"
                                label={"Answer"}
                                size={'small'}
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                      },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.light_text_accent
                                     },
                                     '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: (reveal ? ((answer === currentQuestion.answer) ? Colors.def_green : Colors.def_red) : Colors.sft_blue)
                                      }, borderRadius: 3, marginTop: 0} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.dark_text_accent
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: (reveal ? ((answer === currentQuestion.answer) ? Colors.def_green : Colors.def_red) : Colors.sft_blue)
                                        }, borderRadius: 3, marginTop: 0}}
                                value={answer}
                                onChange={(event)=>{setAnswer(event.target.value)}}
                                />
                            
                            {reveal && <p style={{textAlign: "left"}}><b>Answer:</b> {currentQuestion.answer}</p>}
                        </div>
                    </div>}
                    {currentQuestion.question_type === "choice" && <div className={" columnContainerStart "}>
                        <div className={" rowContainerBetween "}>
                            <Button variant="contained" disabled={loading || disable} className={" quiz_answer_button " + (reveal ? (currentQuestion.answer === currentQuestion.option_a ? " blink-1 green " : " red ") : (answer === currentQuestion.option_a ? " quiz_answer_button_selected " : ""))} onClick={()=>{setAnswer(currentQuestion.option_a)}}>
                                {currentQuestion.option_a}
                            </Button>
                            <Button variant="contained" disabled={loading || disable} className={" quiz_answer_button " + (reveal ? (currentQuestion.answer === currentQuestion.option_b ? " blink-1 green " : " red ") : (answer === currentQuestion.option_b ? " quiz_answer_button_selected " : ""))} onClick={()=>{setAnswer(currentQuestion.option_b)}}>
                                {currentQuestion.option_b}
                            </Button>
                        </div>
                        <div className={" rowContainerBetween "}>
                            <Button variant="contained" disabled={loading || disable} className={" quiz_answer_button " + (reveal ? (currentQuestion.answer === currentQuestion.option_c ? " blink-1 green " : " red ") : (answer === currentQuestion.option_c ? " quiz_answer_button_selected " : ""))} onClick={()=>{setAnswer(currentQuestion.option_c)}}>
                                {currentQuestion.option_c}
                            </Button>
                            <Button variant="contained" disabled={loading || disable} className={" quiz_answer_button " + (reveal ? (currentQuestion.answer === currentQuestion.option_d ? " blink-1 green " : " red ") : (answer === currentQuestion.option_d ? " quiz_answer_button_selected " : ""))} onClick={()=>{setAnswer(currentQuestion.option_d)}}>
                                {currentQuestion.option_d}
                            </Button>
                        </div>
                    </div>}
                    <Button 
                        disabled={loading} 
                        variant="outlined" 
                        sx={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, color: Colors.sft_blue}}
                        onClick={()=>{next();}}>
                        {reveal ? props.t('next') : props.t('check')}
                    </Button>
                </div>}
                
            </div>}
            {(typeof props.login?.first_name === "undefined") && <div className={"general_card_container border_radius mobile_card text_center columnContainerBetween " + props.theme}>
                <p>You need to be logged in to access this page...</p>
             </div>}
        </div>
    );
}

export default QuizScreen;