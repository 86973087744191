import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faPencilAlt, faCopy  } from '@fortawesome/free-solid-svg-icons';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { FormControlLabel, Switch } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import ListItemV2 from '../../components/ListItemV2';
import FloatingAddButton from '../../components/FloatingAddButton';



function EnhancedTableHead(props) {
    const {  order, orderBy, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow className={props.theme}>
          {headCells.map((headCell) => (
            <TableCell
              className={props.theme}
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                className={props.theme + " bold"}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const headCells = [
    {
        id: 'expand',
        numeric: false,
        disablePadding: false,
        label: '',
    },
    {
      id: 'sTimeDate',
      numeric: false,
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'sTimeStart',
      numeric: false,
      disablePadding: false,
      label: 'Time start',
    },
    {
      id: 'sTimeEnd',
      numeric: false,
      disablePadding: false,
      label: 'Time end',
    },
    {
      id: 'sStatus',
      numeric: true,
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'sMailsAdded',
      numeric: true,
      disablePadding: false,
      label: 'Added',
    },
    {
      id: 'sMailsUpdated',
      numeric: true,
      disablePadding: false,
      label: 'Updated',
    },
    {
      id: 'sMailsErrors',
      numeric: true,
      disablePadding: false,
      label: 'Errors',
    },
  ];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    const formatErrors = (errorStr) =>{
      let errorObj = JSON?.parse(errorStr);
       console.log("errorObj:", errorObj);
       return Object.keys(errorObj).map((key) => {
        let test = errorObj[key];
        console.log("test:", test);


        switch (key) {
          case 'subscribeProfiles':
          case 'unsubscribeProfiles':
            return (
              <div key={key}>
                <h3>{key}</h3>
                <p>{ errorObj[key]}</p>
              </div>
            )
          case 'bulkImportErrors':

          return (
            <div key={key}>
              <h3>{key}</h3>
              <p>{ errorObj[key]?.map(
                (batch) => {
                  if (batch.errors) {
                    return (
                      <div key={batch.batch}>
                        <p>
                        <h4>Batch: {batch.batch}</h4>
                          <ul>
                            {JSON?.parse(batch.errors).map((error) => {
                              return (
                                <li>
                                Status {error?.status} ERROR: {error?.title} : {error?.detail} {error?.source?.pointer}
                                </li>
                              )
                            }
                            )}
                          </ul>
                          </p>
                        </div>
                      )
                  }
                }
              )}</p>
            </div>
          )
          default:
            return (
              <div key={key}>
                <h3>{key}</h3>
                <p>{ errorObj[key]}</p>
              </div>
            )
          };
      }
    )}

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell className={props.theme} align="left">{row.date}</TableCell>
          <TableCell className={props.theme} align="left">{row.start_time}</TableCell>
          <TableCell className={props.theme} align="left">{row.end_time}</TableCell>
          <TableCell className={props.theme} align="right">{row.status}</TableCell>
          <TableCell className={props.theme} align="right">{row.added}</TableCell>
          <TableCell className={props.theme} align="right">{row.updated}</TableCell>
          <TableCell className={props.theme} align="right">{row.error}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography className={props.theme} variant="h6" gutterBottom component="div">
                  Errors
                </Typography>
                <div className={props.theme} style={{height: "fit-content"}} >

                    {/* 
                    
                      error is inside   "error_information": "{\"subscribeProfiles\":[],\"unsubscribeProfiles\":[],\"bulkImportErrors\":[]}", for each key add a title and show the value 

                    
                    */}
                    {formatErrors(row.error_information)}
                    {/* {addLineBreak(row.sMailsErrorsL)} */}
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  
  Row.propTypes = {
    row: PropTypes.shape({
      sTimeDate: PropTypes.string.isRequired,
      sTimeStart: PropTypes.string.isRequired,
      sTimeEnd: PropTypes.string.isRequired,
      sStatus: PropTypes.string.isRequired,
      sTime: PropTypes.string.isRequired,
      sMailsProcessed: PropTypes.string.isRequired,
      sMailsAdded: PropTypes.string.isRequired,
      sMailsUpdated: PropTypes.string.isRequired,
      sMailsErrors: PropTypes.string.isRequired,
      sMailsErrorsL: PropTypes.string.isRequired,
    }).isRequired,
  };

  function RowWebhook(props) {
    const { row } = props;
  
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell className={props.theme} align="left">{SftGlobalData.dateTimeFormatter(row.rTime)}</TableCell>
          <TableCell className={props.theme} align="left">{row.rType}</TableCell>
          <TableCell className={props.theme} align="left">{row.rListID}</TableCell>
          <TableCell className={props.theme} align="left">{row.rSubID}</TableCell>
          <TableCell className={props.theme} align="left">{row.rEmail}</TableCell>
          <TableCell className={props.theme} align="left">{row.rFree1}</TableCell>
          <TableCell className={props.theme} align="left">{row.rFree2}</TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  
  RowWebhook.propTypes = {
    row: PropTypes.shape({
      rID: PropTypes.string.isRequired,
      rTime: PropTypes.string.isRequired,
      rType: PropTypes.string.isRequired,
      rListID: PropTypes.string.isRequired,
      rSubID: PropTypes.string.isRequired,
      rEmail: PropTypes.string.isRequired,
      rFree1: PropTypes.string.isRequired,
      rFree2: PropTypes.string.isRequired,
    }).isRequired,
  };


  const gridWebhookColumns = [
    {
        field: 'rTime',
        headerName: 'Time',
        width: 180,
        display: 'flex',
    },
    {
        field: 'rType',
        headerName: 'Type',
        width: 100,
        display: 'flex',
    },
    {
        field: 'rListID',
        headerName: 'List ID',
        width: 110,
        display: 'flex',
    },
    {
        field: 'rSubID',
        headerName: 'Sub ID',
        width: 110,
        display: 'flex',
    },
    {
        field: 'rEmail',
        headerName: 'Email',
        width: 280,
        display: 'flex',
    },
    {
        field: 'rFree1',
        headerName: 'Free 1',
        width: 130,
        display: 'flex',
    },
    {
        field: 'rFree2',
        headerName: 'Free 2',
        width: 100,
        display: 'flex',
    },
  ];


  

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ div: 3 }}>
            <div style={{marginTop: 10}}>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const MailIntegrationsScreen = (props) => {
    const [loading, setLoading] = useState(false);
    const [lists, setLists] = useState([]);
    const [value, setValue] = useState(0);
    const [logRows, setLogRows] = useState([]);
    const [logRowsWebhook, setLogRowsWebhook] = useState([]);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('date');
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const gridIntegrationColumns = [
        {
            field: 'id',
            headerName: "ID",
            width: 50,
            align: "left",
        },
        {
            field: 'type',
            headerName: "Type",
            headerClassName: 'bold',
            minWidth: 100,
            width: 150,
            align: "left",
        },
        {
            field: 'description',
            headerName: props.t('mail_desc'),
            headerClassName: 'bold',
            minWidth: 200,
            width: 300,
            align: "left",
        },
        {
            field: 'list_id',
            headerName: props.t('mail_list_id'),
            headerClassName: 'bold',
            minWidth: 100,
            width: 175,
            align: "left",
        },
        {
            field: 'last_sync_started',
            headerName: props.t('mail_last_sync_started'),
            headerClassName: 'bold',
            minWidth: 100,
            width: 200,
            align: "left",
        },
        {
            field: 'last_sync_end',
            headerName: props.t('mail_last_sync_end'),
            headerClassName: 'bold',
            minWidth: 100,
            width: 200,
            align: "left",
        },
        {
            field: 'active',
            headerName: props.t('active'),
            width: 125,
            align: "left",
            renderCell: (params)=>{return <ListItemV2 
                key={params.row.id} 
                id={params.row.id} 
                lang={props.lang} 
                name={params.row.description}
                config={params.row.sms_birthday_body} 
                active={params.row.active ? 'Y' : 'N'} 
                api={props.api} 
                theme={props.theme}
                submitHandler={(list,value)=>{changeMailCredentials(list,value)}}
                />}
        },
        {
            field: 'edit',
            headerName: props.t('edit'),
            minWidth: 60,    
            maxWidth: 60,  
            align: 'center',      
            headerAlign: 'center', 
            renderCell: (params) => {
            return  <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%" }} onClick={()=>{navigate("/"+props.lang+"/mail-integrations/"+params.row.id)}} >
                        <FontAwesomeIcon icon={faPencilAlt} color={Colors.def_orange} size="2x" />
                    </Button>;        
            }
        },
      ];

    let navigate = useNavigate();


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    useEffect(() => {
        if(props.api !== ""){
            props.changeScreen('Licence');
            let url = SftGlobalData.baseURL_API + 'mailIntegration&action=fetch';
            axios.post(url, {key: props.api}, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('response', response);
                }
                setLists(response.data);
                url = SftGlobalData.baseURL_API + 'mailIntegration&action=getSyncLog';
                axios.post(url, {key: props.api}, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('response', response);
                    }
                    setLogRows(response.data);

                    url = SftGlobalData.baseURL_API + 'sftData';
                    axios.post(url, {key: props.api, type: 'mailLogWebhook'}, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }})
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('response', response);
                        }
                        if(typeof response.data.data !== "undefined"){
                            setLogRowsWebhook(response.data.data);
                        }
                        setLoading(false);
                    });
                });
            });
            setLoading(false);
        }
    },[props.api]);

    const changeMailCredentials = (list, active) => {
        let url = SftGlobalData.baseURL_API + 'mailIntegration&action=updateActive';
        let postData = {
            key: props.api, 
            id: list,
            active: active === 'Y' ? '1' : '0'
        };
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('postData', postData);
                console.log('response', response);
            }
        });
    }

    return (
        <div className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme}>
            
            <FloatingAddButton disabled={loading} onClick={()=>{navigate("/"+props.lang+"/mail-integrations/+")}} />
            <div className={"container_list__title " + props.theme}><h2>Mail integration</h2></div>

            <div style={{marginTop: 30}}>
                <br/><br/>
            {<Box sx={{ width: '100%' }} className={props.theme}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider'}} className={props.theme + ' container_tabs_below_subtitle'}>
                  <Tabs value={value} onChange={handleChange} className={props.theme}>
                        <Tab label="Lists" {...a11yProps(0)} className={props.theme} />
                        <Tab label="Sync log" {...a11yProps(1)} className={props.theme} />
                        {lists.filter(x => x.type === 'MailChimp').length > 0 && (
                            <Tab label="Webhook MailChimp" {...a11yProps(2)} className={props.theme} />
                        )}
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <div>
                        <DataGridPro
                        className={props.theme}
                        columns={gridIntegrationColumns}
                        rows={lists.map((row, index) => {return {
                                    id: row.id, 
                                    type: row.type,
                                    description: row.description,
                                    list_id: row.list_id,
                                    last_sync_started: row.last_sync_started,
                                    last_sync_end: row.last_sync_ended,
                                    active: (row.active === "1"),
                                    edit: "EDIT",
                                    copy: "COPY",
                                    delete: "DELETE"
                                }
                            })}
                          loading={loading}
                          rowHeight={38}
                          autoHeight={true}
                          pagination={true}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {logRows.length !== 0 && <div>
                        <Paper sx={{ width: '100%', mb: 2 }}>
                            <TableContainer component={Paper} className={props.theme}>
                                <Table aria-label="collapsible table" className={props.theme} size={dense ? 'small' : 'medium'}>
                                        <EnhancedTableHead
                                            theme={props.theme}
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                            rowCount={logRows.length}
                                        />
                                    <TableBody>

                                    {stableSort(logRows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        //const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <Row key={row.date + " " + row.start_time} theme={props.theme} row={row} tabIndex={-1}/>
                                        )
                                    })}

                                    {/* {emptyRows > 0 && (
                                        <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}
                                        >
                                            <TableCell colSpan={8} />
                                        </TableRow>
                                    )} */}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                className={props.theme}
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={logRows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                        </Paper>
                        <FormControlLabel
                            control={<Switch checked={dense} onChange={handleChangeDense} />}
                            label="Dense padding"
                        />
                    </div>}
                    {logRows.length === 0 && <div>
                        <center><p>No mail sync logging for client.</p></center>
                    </div>}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div style={{width: '100%' }}>
                    <DataGridPro
                    className={props.theme}
                    columns={gridWebhookColumns}
                    rows={logRowsWebhook.map((row, index) => {return {
                                id: row.rID, 
                                rTime: SftGlobalData.dateTimeFormatter(row.rTime),
                                rType: row.rType,
                                rListID: row.rListID,
                                rSubID: row.rSubID,
                                rEmail: row.rEmail,
                                rFree1: row.rFree1,
                                rFree2: row.rFree2
                            }
                        })}
                      loading={loading}
                      rowHeight={38}
                      pagination={true}
                      autoHeight={true}
                    />
                  </div>
                </TabPanel>
            </Box>}
            </div>        
        </div>
    );
}

export default MailIntegrationsScreen;
