import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
  } from 'react-router-dom';
import App from './App';
//import loaderGif from './assets/loader.gif';
import './index.css';
import "./i18n";
import reportWebVitals from './reportWebVitals';
import registerServiceWorker from './registerServiceWorker';
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey('42d0afbb03d500893edcce3da30c8b88Tz0xMDY1MjMsRT0xNzcxMDI3MTk5MDAwLFM9cHJvLExNPXBlcnBldHVhbCxQVj1RMy0yMDI0LEtWPTI=');
// LicenseInfo.setLicenseKey('3083c728ddcfc391bf9a1a99323b75a7Tz04NDE1MixFPTE3Mzk0MzMzNTEwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=');

ReactDOM.render(
  (<Suspense fallback={
    (
        <div className="center_fallback">
            <div className="loading-container">
                <div className="loading"></div>
                <div id="loading-text">Loading</div>
            </div>
            {/*<img src={loaderGif} alt="logo" />*/}
        </div>
    )}>
       <Router> 
            <Routes>
                <Route path='/' element={<Navigate to={"/nl/login"}/>}/>
                <Route path='/:lang/*' element={<App />} />
            </Routes> 
       </Router>
    </Suspense>),
  document.getElementById('root')

);
registerServiceWorker();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
