import { Button, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LicenceConnectionsOverviewForm from '../components/licence/LicenceConnectionsOverviewForm';
import SftGlobalData from '../library/SftGlobalData';
import FloatingSyncButton from '../components/FloatingSyncButton';


const ConnectionOverviewScreen = (props) =>  {  
    console.log("props ConnectionOverviewScreen:", props);
    useEffect(() => {
        props.changeScreen('logging');
        
      }, [props.api]);

    return (
        <div className={'_100perc log__main_container log__padding_sidebar'}>
            <FloatingSyncButton disabled={false} url={SftGlobalData.baseURL_auth + "cust_sync.php"} bottom={true}/>
            <div className={'log__container_content ' + props.theme + ' container_content_' + props.theme + ' box_fade-in margin_top_50px'} >
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                    
                    {
                        // --------------------------------------------------------------------
                        // TITLE / HEADER
                        // --------------------------------------------------------------------
                    }
                    <div className={props.theme + " _100perc "}>
                        <div className="container_list__title ">
                            <h2 className={props.theme} type="text" id="logging" name="logging">Connections</h2>
                        </div>
                        <hr />
                    </div>
                </div>
                
                <LicenceConnectionsOverviewForm screenWidth={props.screenWidth} login={props.loginObj} username={props.username} userId={props.userId} api={props.api} theme={props.theme} t={props.t} limited={false} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}} />
            </div>
        </div>
    );
}

export default ConnectionOverviewScreen;
