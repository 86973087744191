import React, { useState, useEffect }from 'react';
import SftGlobalData from '../../library/SftGlobalData';
import Button from '@mui/material/Button';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { DataGridPro, useGridApiContext } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import { Input } from '@mui/material';
import { styled } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import LicenceDoorCounterGenerateModal from './LicenceDoorCounterGenerateModal';

function CustomEditComponent(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
  
    const handleValueChange = (event) => {
      const newValue = event.target.value; 

      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    const saveValueChange = () => {
        try{
            let url = SftGlobalData.baseURL_API+'client&action=updateDoorCounter';
            let postData = {key: props.sftapi, id: id, field: props.field, value: value, user: props.username, sftUserId: props.userId};
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                });
        } catch(e){
            console.error(e);
        }
    }
  
    return <input type="text" value={value} onChange={handleValueChange} onBlur={()=>{saveValueChange()}}/>;
  }

const LicenceDoorCounterForm = (props) =>  {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [location, setLocation] = useState("");
    const [locId, setLocId] = useState("");
    const [storeId, setStoreId] = useState("");
    const [posId, setPosId] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [refresh, setRefresh] = useState(false);


    const StyledModal = styled(ModalUnstyled)`
    position: fixed;
    text-align: center;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

    const Backdrop = styled('div')`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    `;

    const style = {
    border: '0px solid #000',
    borderRadius: 6,
    p: 2,
    px: 4,
    pb: 3,
    };

    const fetchDoorcounters = () => {
        try{
            let url = SftGlobalData.baseURL_API+'client&action=fetchDoorCounter';
            let postData = {key: props.api}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setData(response.data);
                    setLoading(false);
                });
        } catch(e){
            console.error(e);
        }
    }

    useEffect (()=>{
        fetchDoorcounters();
    }, [props.api]);

    const gridWebhookColumns = [
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 60,
            maxWidth: 60,
            width: 60,
            display: 'flex',
        },
        {
            field: 'locId',
            headerName: 'Location ID',
            minWidth: 60,
            editable: true,
            display: 'flex',
            renderEditCell: (params)=>{return <CustomEditComponent field={"LocID"} sftapi={props.api} {...params}/>}
        },
        {
            field: 'location',
            headerName: 'Location',
            minWidth: 150,
            width: 150,
            editable: true,
            display: 'flex',
            renderEditCell: (params)=>{return <CustomEditComponent field={"Location"} sftapi={props.api} {...params}/>}
        },
        {
            field: 'store',
            headerName: 'Store',
            minWidth: 75,
            editable: true,
            display: 'flex',
            renderEditCell: (params)=>{return <CustomEditComponent field={"mag"} sftapi={props.api} {...params}/>}
        },
        {
            field: 'pos',
            headerName: 'POS',
            minWidth: 75,
            editable: true,
            display: 'flex',
            renderEditCell: (params)=>{return <CustomEditComponent field={"kassa"} sftapi={props.api} {...params}/>}
        },
      ];

    function DataGrid() {
        return (
            <div className={props.theme + " _70perc"}>
            
                {props.accountId !== "0" && <DataGridPro
                    className={props.theme}
                    columns={gridWebhookColumns}
                    rows={data.map((row) => {return {
                            id: row.id,
                            locId: row.LocID,
                            location: row.Location,
                            pos: row.kassa,
                            store: row.mag
                        }
                    })}
                    //{...data}
                    rowHeight={38}
                    style={{height: 350}}
                    pagination={false}
                    loading={loading}

                    />   }       
            </div>
        );
    }

    const onAdd = async () => {
        try{
            let url = SftGlobalData.baseURL_API+'client&action=addDoorCounter';
            let postData = {
                key: props.api,
                location: location,
                loc_id: locId,
                store_id: storeId,
                pos_id: posId, user: props.username, sftUserId: props.userId
            }
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    let tempData = data;
                    tempData.push(response.data);
                    setData(tempData);
                    setLocation("");
                    setLocId("");
                    setStoreId("");
                    setPosId("");
                });
        } catch(e){
            console.error(e);
        }
    }

    const onGenerate = async () => {
        try{
            setOpenModal(true);
        } catch(e){
            console.error(e);
        }
    }


    return (
        <div className={props.theme} style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
            {DataGrid()}
            <div className={props.theme + " _25perc margin_left__15px"}>
            <div className="rowContainerBetween margin_bottom_15px" style={{ width: '100%', alignItems: 'center', alignContent: 'center'}} >
                <p><b>Add doorcounter</b> </p>
                <SmartToyIcon onClick={() => { onGenerate() }} />
            </div>
                <StyledModal
                    aria-labelledby="unstyled-modal-title"
                    aria-describedby="unstyled-modal-description"
                    open={openModal}
                    onClose={() => { setOpenModal(false); }}
                    BackdropComponent={Backdrop}
                >
                    <Box sx={style} style={{ minWidth: 250, minHeight: 250 }} className={props.theme + " _50perc"}>
                        <div>
                            <LicenceDoorCounterGenerateModal login={props.loginObj} t={props.t} selectedDb={props.selectedDb} is_store={false} api={props.api} theme={props.theme} userId={props.userId} username={props.username} success={() => {fetchDoorcounters();}} refresh={() => { setRefresh(!refresh); setOpenModal(false); props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1); }} />
                        </div>
                    </Box>
                </StyledModal>

                <Input className={props.theme + " _100perc margin_top_10px"} placeholder='Location' value={location} onChange={(e) => { setLocation(e.target.value); }} /> <br />
                <Input className={props.theme + " _100perc margin_top_10px"} placeholder='LocID' value={locId} onChange={(e) => { setLocId(e.target.value); }} /> <br />
                <Input className={props.theme + " _100perc margin_top_10px"} placeholder='Store' value={storeId} onChange={(e) => { setStoreId(e.target.value); }} /> <br />
                <Input className={props.theme + " _100perc margin_top_10px"} placeholder='POS' value={posId} onChange={(e) => { setPosId(e.target.value); }} /> <br />
                <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc"} onClick={() => { onAdd(); }}>{props.t('add')}</Button>
            </div>
        </div>
    );
}

export default LicenceDoorCounterForm;
