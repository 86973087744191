import axios from "axios";
import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import SftGlobalData from "../../library/SftGlobalData";
import Colors from "../../library/Colors";
import MySoftTouch from '../../assets/application-icons/ic_mysft.png';
import MySales from '../../assets/application-icons/ic_mysales.png';
import MyFashionManager from '../../assets/licence-icons/office.png';
import Consultation from '../../assets/mobile-icons/ic_consultation.png';
import Collection from '../../assets/licence-icons/collection.png';
import Cloud from '../../assets/licence-icons/cloud.png';
import MyFasmanMobile from '../../assets/module-icons/ic_mobile.png';
import MyFasmanRevenue from '../../assets/application-icons/ic_revenue.png';
import Webshop from '../../assets/mobile-icons/ic_webshop.png';
import Integrations from '../../assets/licence-icons/integrations.png';
import Hardware from '../../assets/licence-icons/hardware.png';
import Others from '../../assets/licence-icons/other.png';
import { Box, Button, Card, Fab, Tooltip } from "@mui/material";
import { IntegrationInstructions, Person, Print, SupportAgent } from "@mui/icons-material";

const HelpScreen = (props) =>  {
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [applicationResult, setApplicationResult] = useState([]);
    const [htmlData, setHtmlData] = useState(<div></div>);
    const [selectedDoc, setSelectedDoc] = useState({});
    const params = useParams();
    let navigate = useNavigate();

    // TODO: When softtouch user, show on top right corner of the manual the visibility of the manual
    // TODO: When softtouch user, if you hover over the visibility of the manual, show who created it (maybe who updated or approved it too)

    //---------------------------------------------------------------------------------------------
    //    FUNCTIONS
    //---------------------------------------------------------------------------------------------

    useEffect(()=>{
        props.changeScreen('help');
        if(typeof props.login !== "undefined" && typeof params?.id !== "undefined"){
            
            if(params.id === "search"){
                // Search flow
                let search = params.value + "";
                let url = SftGlobalData.baseURL_API + 'docs&action=searchDoc';
                let postData = {
                    sft_id: props.login.sft_id,
                    sft_group: props.login.sft_group, 
                    key: props.api, 
                    visibility: (props.login?.sft_status !== "1" ? "CUST" : props.login?.sft_group),
                    language: props.lang,
                    login: props.login.login,
                    search: search
                    };
                
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('response', response);
                    }
                    setSearchResults(response.data);
                    setLoading(false);
                });

            } else if(params.id === "application"){
                // App flow
                let appId = params.value + "";
                let url = SftGlobalData.baseURL_API + 'docs&action=sidebar';
                let postData = {
                    sft_id: props.login.sft_id,
                    sft_group: props.login.sft_group, 
                    language: props.lang,
                    key: props.api, 
                    visibility: (props.login?.sft_status !== "1" ? "CUST" : props.login?.sft_group),
                    login: props.login.login,
                    app_id: appId
                    };
                
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('response', response);
                    }
                    if(response.data?.length > 0)
                        setApplicationResult(response.data)
                    setLoading(false);
                });
            } else {
                // View manual flow
                let manualId = params.id + "";
                let url = SftGlobalData.baseURL_API + 'docs&action=fetchManual';
                let postData = {
                    sft_id: props.login.sft_id,
                    sft_group: props.login.sft_group, 
                    language: props.lang,
                    key: props.api, 
                    visibility: (props.login?.sft_status !== "1" ? "CUST" : props.login?.sft_group), 
                    login: props.login.login,
                    id: manualId,
                    customer: 1,
                    };
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('response', response);
                    }
                    try{
                        if(typeof response.data.detail[0]?.html === "undefined")
                            navigate("/"+props.lang+"/kb");
                        else {
                            setSelectedDoc(response.data);
                            setHtmlData(response.data.detail[0].html);
                        }
                    }catch(error){
                        navigate("/"+props.lang+"/kb");
                    }
                    
                    setLoading(false);
                });
            }
        }
    }, [props.api, params?.id, params?.value, props.lang]);

    const handlePrint = () => {
        const printContents = htmlData;
        const printWindow = window.open("", "_blank");
        printWindow.document.open();
        printWindow.document.write(`
            <html>
                <head>
                    <title>Print</title>
                    <link rel="stylesheet" href="https://my.softtouch.eu/files/fonts/stylesheet.css">
                    <style>
                        body { font-family: 'Urbane Rounded', 'Ubuntu', sans-serif !important; margin: 0px; }
                        span {
                            font-family: 'Urbane Rounded', 'Ubuntu', sans-serif !important;
                        }
                        p {
                            font-family: 'Urbane Rounded', 'Ubuntu', sans-serif !important;
                        }
                        a {
                            font-family: 'Urbane Rounded', 'Ubuntu', sans-serif !important;
                        }
                        .font {
                            font-family: 'Urbane Rounded', 'Ubuntu', sans-serif !important;
                        }
                        h1 {
                            background-color: #002c41 !important;
                        }
                        h3 {
                            background-color: #c0e4da !important;
                        }
                    </style>
                </head>
                <body>
                    ${printContents}
                </body>
                <script>
                    console.log("test");
                    // Wait for the entire document to load
                    window.onload = function() {
                        console.log("Document loaded. Triggering print...");
                        window.print();
                    };
                </script>
            </html>
        `);
        printWindow.document.close();
    };

    const getApplicationIcon = (names) => {
        switch (names){
            case "1":
            case "MySales": 
                return MySales;
            case "2":
            case "MyFashionManager": 
                return MyFashionManager;
            case "3":
            case "Consultation": 
                return Consultation;
            case "4":
            case "Collection": 
                return Collection;
            case "5":
            case "Cloud": 
                return Cloud;
            case "6":
            case "MyFasman Mobile": 
                return MyFasmanMobile;
            case "7":
            case "MyFasman Revenue": 
                return MyFasmanRevenue;
            case "8":
            case "Webshop": 
                return Webshop;
            case "9":
            case "Integrations": 
                return Integrations;
            case "10":
            case "Hardware": 
                return Hardware;
            case "11":
            case "MySoftTouch":
                return MySoftTouch;
            default:
            case "12":
            case "Others": 
                return Others;       
        }
      }

    const getSearchResults = searchResults.map((menu, idx) => (
        <Card key={{idx}} style={{width: 450, padding: 10, margin: 10}}>
            <h4 className="bold rowContainerStart alignCenterInContainer "><img src={getApplicationIcon(menu.application_id)} alt={menu.application_name} width="32px"/> &nbsp;&nbsp;&nbsp; {menu.application_name}</h4>
            <hr/>
            {menu.docs.map((doc, idy) => (<Button style={{width: "100%", textAlign: "left", justifyContent: "flex-start"}} onClick={()=>{navigate("/"+props.lang+"/kb/"+doc.id)}}>{doc.title}</Button>))}
        </Card>
    ));

    const getApplicationResult = applicationResult.map((menu, idx) => (
        <Card key={{idx}} style={{width: 450, padding: 10, margin: 10}}>
            <h4 className="bold rowContainerStart alignCenterInContainer "><img src={getApplicationIcon(menu.application_id)} alt={menu.application_name} width="32px"/> &nbsp;&nbsp;&nbsp; {menu.application_name}</h4>
            <hr/>
            {menu.docs.map((doc, idy) => (<Button style={{width: "100%", textAlign: "left", justifyContent: "flex-start"}} onClick={()=>{navigate("/"+props.lang+"/kb/"+doc.id)}}>{doc.title}</Button>))}
        </Card>
    ));

    const getVisibilityIcon = () => {
        try{
            switch(selectedDoc?.visibility){
                case "DEV": 
                    return <Tooltip title='DEV' arrow><IntegrationInstructions style={{color: Colors.sft_blue}}/></Tooltip>
                case "INTERNAL":
                    return <Tooltip title='INTERNAL' arrow><SupportAgent style={{color: Colors.sft_blue}}/></Tooltip>
                default:
                    return <Tooltip title='CUSTOMER' arrow><Person style={{color: Colors.sft_blue}}/></Tooltip>
            }
        }catch(error){

        }
    }

    

    //---------------------------------------------------------------------------------------------
    //    OUTPUT
    //---------------------------------------------------------------------------------------------

    return (
        <div className={((props.screenWidth) < 600 ? 'font _80perc container_content ' + (props.hideSidebar ? ' not_expanded ' : ' expanded ') + props.theme + ' container_content_' + props.theme : 'font _80perc container_content ' + (props.hideSidebar ? ' not_expanded ' : ' expanded ') + props.theme + ' container_content_' + props.theme)} >
            {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}
            {(typeof params?.id !== "undefined" && params?.id !== "search" && params?.id !== "application") && <div style={{position: "relative", display: "block"}}><div className="font" style={{height: "fit-content", fontFamily: "Urbane Rounded"}} dangerouslySetInnerHTML={{__html: htmlData}}></div>{props?.login?.sft_status !== "0" && <div style={{position: "absolute", float: "right", top: 3, right: 3}}>{getVisibilityIcon()}</div>}</div>}
            {typeof params?.id === "undefined" && <div>
                <h1>{props.t('manuals_title')}</h1>
                <p>{props.t('manuals_descr')}</p>
            </div>}

            {params?.id === "search" && <div>
                <h1 style={{textAlign: "center"}}>{props.t('search')}</h1>
                <div className="rowContainerCenter">
                    {getSearchResults}
                </div>
            </div>}

            {params?.id === "application" && <div>
                <h1 style={{textAlign: "center"}}>{props.t('manuals')}</h1>
                <div className="rowContainerCenter">
                    {getApplicationResult}
                </div>
            </div>}
            {(typeof params?.id !== "undefined" && params?.id !== "search" && params?.id !== "application") && <Box disabled={props.disabled} onClick={()=>{handlePrint();}} style={{position: 'fixed', right: 14, bottom: 24, zIndex: 10}} sx={{ '& > :not(style)': { m: 1 } }}>
              <Fab size="medium" color={"primary"} aria-label="Print">
                <Print />
              </Fab>
            </Box>}
        </div>
    );
}

export default HelpScreen;